@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

body.modal-open > div:last-child{
  top:30px !important;
}
.grecaptcha-badge{
  z-index: 99999 !important;
}

body.menu-is-show {
  overflow: hidden;
}
div[class$="-option"]:active {
  background-color: #4D5F68;
}

.full-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
}
.full-spinner.background-load {
  background: #ffffffed;
}
.full-spinner.md .loadingio-spinner-ripple {
  width: 104px;
  height: 104px;
}
.full-spinner.md .ldio {
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
}
.full-spinner.md .ldio div {
  border-width: 2.08px;
  -webkit-animation: ldio-md 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: ldio-md 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.full-spinner.xs .loadingio-spinner-ripple {
  width: 38px;
  height: 38px;
}
.full-spinner.xs .ldio {
  -webkit-transform: translateZ(0) scale(0.38);
          transform: translateZ(0) scale(0.38);
}
.full-spinner.xs .ldio div {
  border-width: 2px;
  -webkit-animation: ldio-xs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: ldio-xs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: ldio 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: ldio 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio div:nth-child(1) {
  border-color: #9aca3c;
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
}
.ldio div:nth-child(2) {
  border-color: #65757a;
  -webkit-animation-delay: -0.5s !important;
          animation-delay: -0.5s !important;
}
.loadingio-spinner-ripple {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.background-load .loadingio-spinner-ripple {
  background: #ffffffed;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ldio div {
  box-sizing: content-box;
}
@media(max-width: 613px){
  body.modal-open > div:last-child{
    top:0px !important;
  }
  .notification-container{
    right: auto;
    top: 40px;
  }
  .notification-leave.notification-leave-active
  {
    visibility:hidden;
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0);
    transition:all .4s
  }
  .notification-enter
  {
    visibility:hidden;
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0)
  }
}
@-webkit-keyframes ldio {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
@keyframes ldio {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
@-webkit-keyframes ldio-md {
  0% {
    top: 49.92px;
    left: 49.92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9.36px;
    left: 9.36px;
    width: 81.12px;
    height: 81.12px;
    opacity: 0;
  }
}
@keyframes ldio-md {
  0% {
    top: 49.92px;
    left: 49.92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9.36px;
    left: 9.36px;
    width: 81.12px;
    height: 81.12px;
    opacity: 0;
  }
}
@-webkit-keyframes ldio-xs {
  0% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9px;
    left: 9px;
    width: 78px;
    height: 78px;
    opacity: 0;
  }
}
@keyframes ldio-xs {
  0% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9px;
    left: 9px;
    width: 78px;
    height: 78px;
    opacity: 0;
  }
}
.pac-container {
  z-index: 9999;
}

